//Icon Imports
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowRight,
  faBook,
  faCheckCircle,
  faComments,
  faDatabase,
  faEnvelope,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileWord,
  faHandPaper,
  faPlus,
  faQuestionCircle,
  faSync,
  faTimes,
  faTrash,
  faUser,
  faUserCircle,
  faSearch,
  faArrowLeft,
  faTimesCircle,
  faCircle,
  faCog,
  faSignOutAlt,
  faWindowClose,
  faEdit,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faArrowRight,
  faBook,
  faCheckCircle,
  faComments,
  faDatabase,
  faEnvelope,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileWord,
  faHandPaper,
  faPlus,
  faQuestionCircle,
  faSync,
  faTimes,
  faTrash,
  faUser,
  faUserCircle,
  faSearch,
  faArrowLeft,
  faTimesCircle,
  faCircle,
  faCog,
  faSignOutAlt,
  faWindowClose,
  faEdit,
  faGlobe
);
